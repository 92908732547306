<template>
    <div id="app">
        <br>
        <div align="center">
            <span class="text-h4">Entra in piattaforma Rami</span>
            <hr>
        </div>

        <div class="q-pa-md text-center">
            <div class="row justify-center">
                <QQButton label="ENTRA!"
                    color="blue-grey"
                    icon="mdi-chart-line"
                    size="xl"
                    @click.native="entraInPiattaformaRami()"
                />
            </div>
        </div>

        <QQDialogError
            :is_visible="has_error"
            title="Errore"
            :message="error"
        />
    </div>

</template>

<script>
import QQDialogError from "@/components/QQDialogError";
import QQButton from "@/components/QQButton.vue";
import api from "@/libs/api";
import axios from 'axios';
import commonLib from '@/libs/commonLib';

export default {
    name: "DevelopHome",
    components: {
        QQButton,
        QQDialogError
    },
    data() {
        return {
            jwt: "",
            has_error: false,
            error: "",
        };
    },
    async mounted() {
        this.jwt = await this.simulaLoginDaAbywayIt();
        //console.log(this.jwt);
    },
    methods: {
        async entraInPiattaformaRami() {
            // invia il jwt come query-param
            window.location = process.env.VUE_APP_URL + "?jwt="+ this.jwt;
        },

        async simulaLoginDaAbywayIt() {
            // siamo in abyway.it, login con user e pass fatto

            // clicco su btn piattaforma rami

            // backend abyway fa una richiesta a backend rami per avere il jwt
            // usa autenticazione basic
            axios.defaults.headers.common = {
                //Authorization: 'Basic YmFzaWNVc2VybmFtZTpiYXNpY1Bhc3N3b3Jk'
                Authorization: 'Basic aHZhdng5ZTJlRnRhcms1VjpiUGc5ZzhuQlpVdXFoVzZ1'
            }
            // dati di accesso che abyway invia a backend rami

            // 3287 - De conno Diego
            // 58 - Pietrasanta Daniela Liliana
            // 596 - Poli Simone
            // 14 - Bruno
            // 1270 - Tresoldi Deborah
            // 1310 - Bianciardi Gino
            // 3427 - Susy Parlanti Maria
            // 2550 Bulleri Alessandro
            // 3947 Corvese Andrea
            let post_data = {
                id_persona_operativa: 58 // 14 // 3287 //58 // 1310 // 1270 // 596
            };
            // richiede al BE un nuovo JWT
            let url_backend_rami = process.env.VUE_APP_API_URL + 'auth/createJwt';

            // richiesta
            let create_jwt_response = await api.postJSonToController(url_backend_rami,post_data);

            // nella risposta c'è il jwt
            //console.log("jwt",create_jwt_response);

            if(commonLib.isNull(create_jwt_response)) {
                //console.error("Risposta create jwt nulla",create_jwt_response);
                this.has_error = true;
                this.error = "Risposta create jwt nulla, backend rami irraggiungibile";
                return;
            }

            if(commonLib.isNull(create_jwt_response.data.token)) {
                //console.error("JWT assente, è esploso qualcosa",create_jwt_response.data);
                this.has_error = true;
                this.error = "JWT assente";
                return;
            }

            return create_jwt_response.data.token;

        }
    }
}
</script>

<style scoped>

</style>
